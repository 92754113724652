import React from "react";
import {
  Autocomplete,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import style from "./SelectAutoComplete.module.scss";
import { ExpandMore } from "@mui/icons-material";

interface SelectProps {
  options: Array<{ value: string | number; label: string | number; }>;
  inputProps: { name: string };
  onChange?: Function;
  value?: any;
  placeholder?: string;
  label?: string;
  fullWidth?: any;
  size?: any;
  name?: string;
  multiple?: boolean;
}
const SelectAutoComplete = (props: SelectProps | any) => {
  const {
    options,
    inputProps,
    onChange,
    value,
    placeholder = "Select...",
    label,
    multiple,
    name,
    // size='small',
    isAllowClearValue,
    maxHeight,
    ...rest
  } = props;
  const onChangeSelect = (event: any, value: any, reason: string) => {
    if (isAllowClearValue) {
      onChange && onChange(inputProps.name, value);
      return;
    }
    if (value !== null) {
      onChange && onChange(inputProps.name, value);
      return;
    }
    onChange && onChange(inputProps.name, "");
  };
  return (
    <div
      className={
        multiple
          ? `SelectAutoCompleteMultiWraper ${style["SelectAutoCompleteMultiWraper"]}`
          : `SelectAutoCompleteWraper ${style["SelectAutoCompleteWraper"]}`
      }
    >
      {label && (
        <Typography component="h6" variant="h6" className={style["label"]}>
          {label}
        </Typography>
      )}
      <Autocomplete
        {...rest}
        value={value}
        {...props}
        options={options}
        renderOption={(props, item: any) => {
          return (
            <li {...props} key={item.value}>
              {item?.label}
            </li>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder={placeholder}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
              // size={size}
            />
          );
        }}
        onChange={onChangeSelect}
        popupIcon={<ExpandMore />}
        ListboxProps={{
          style: { maxHeight: maxHeight || "auto" },
        }}
      />
    </div>
  );
};

export default SelectAutoComplete;
