import { makeStyles } from "@mui/styles";
import loadable from "@react-loadable/revised";
import { RingLoading as ComponentLoading } from "Components/RingLoading/RingLoading";
import { CONSTANTS } from "consts";
import { Header } from "Containers";
import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import GameLevels from "./Screens/GameLevels/GameLevels";
import Sidebar from "../Containers/Sidebars/Sidebar";
import style from "./App.module.scss";
import Footer from "Containers/Footer";
import Achievement from "./Screens/Achievement/Achievement";
import GameSetting from "./Screens/GameSetting/GameSetting";
import GameShopCategory from "./Screens/GameShopCategory/GameShopCategory";
import GameShopProduct from "./Screens/GameShopProduct/GameShopProduct";
import GameBetaUser from "./Screens/GameBetaUser/GameBetaUser";
import GameBetaRequest from "./Screens/GameBetaRequest/GameBetaRequest";
import GameInfo from "./Screens/GameInfo/GameInfo";
import StructuredProgram from "./Screens/StructuredProgram/StructuredProgram";

const Admin = loadable({
  loader() {
    return import("./Screens/Admin/Admin");
  },
  loading: ComponentLoading,
});

const SubscriptionCancel = loadable({
  loader() {
    return import("./Screens/SubscriptionCancel/SubscriptionCancel");
  },
  loading: ComponentLoading,
});

const Machine = loadable({
  loader() {
    return import("./Screens/Machine/Machine");
  },
  loading: ComponentLoading,
});

const MonthlyChallenge = loadable({
  loader() {
    return import("./Screens/MonthlyChallenge/MonthlyChallenge");
  },
  loading: ComponentLoading,
});

const Subscription = loadable({
  loader() {
    return import("./Screens/Subscription/Subscription");
  },
  loading: ComponentLoading,
});
const AppleMembership = loadable({
  loader() {
    return import("./Screens/AppleMembership");
  },
  loading: ComponentLoading,
});

const User = loadable({
  loader() {
    return import("./Screens/Users/User");
  },
  loading: ComponentLoading,
});

const DealerLocation = loadable({
  loader() {
    return import("./Screens/DealerLocation/DealerLocation");
  },
  loading: ComponentLoading,
});
const Support = loadable({
  loader() {
    return import("./Screens/SupportCategories/Support");
  },
  loading: ComponentLoading,
});

const SupportPost = loadable({
  loader() {
    return import("./Screens/SupportPost/Support");
  },
  loading: ComponentLoading,
});

const Press = loadable({
  loader() {
    return import("./Screens/Press/Press");
  },
  loading: ComponentLoading,
});

const Plan = loadable({
  loader() {
    return import("./Screens/Plan/Plan");
  },
  loading: ComponentLoading,
});

const ContactMessage = loadable({
  loader() {
    return import("./Screens/ContactMessage");
  },
  loading: ComponentLoading,
})
const General = loadable({
  loader() {
    return import("./Screens/General/General");
  },
  loading: ComponentLoading,
});

const drawerWidth: number = 250;

const useStyles: any = makeStyles(
  (theme: any) =>
    ({
      root: {
        display: "flex",
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: 36,
      },
      menuButtonHidden: {
        display: "none",
      },
      title: {
        flexGrow: 1,
      },
      drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      },
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        minHeight: "100vh",
        overflow: "hidden",
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
      },
      fixedHeight: {
        height: 240,
      },
    } as any)
);

const App: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div id="app" className={style["App"]}>
      <Header />
      <div id="main">
        <Sidebar />
        <div id="container">
          <Route
            path={CONSTANTS.NAVIGATION_URL.SUBSCRIPTION}
            component={Subscription}
          />
          <Route path={CONSTANTS.NAVIGATION_URL.MACHINE} component={Machine} />
          <Route
            path={CONSTANTS.NAVIGATION_URL.SUBSCRIPTION_CANCEL}
            component={SubscriptionCancel}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.MEMBERSHIP}
            component={AppleMembership}
          />
          <Route path={CONSTANTS.NAVIGATION_URL.USER} component={User} />
          <Route
            path={CONSTANTS.NAVIGATION_URL.MONTHLY_CHALLENGE}
            component={MonthlyChallenge}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.DEALER_LOCATION}
            component={DealerLocation}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.GAME_LEVELS}
            component={GameLevels}
          />
          <Route path={CONSTANTS.NAVIGATION_URL.ADMIN} component={Admin} />
          <Route path={CONSTANTS.NAVIGATION_URL.SUPPORT} component={Support} />
          <Route
            path={CONSTANTS.NAVIGATION_URL.SUPPORT_POST}
            component={SupportPost}
          />
          <Route path={CONSTANTS.NAVIGATION_URL.PRESS} component={Press} />
          <Route
            path={CONSTANTS.NAVIGATION_URL.ACHIEVEMENT}
            component={Achievement}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.GAME_SETTING}
            component={GameSetting}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.GAME_SHOP_CATEGORY}
            component={GameShopCategory}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT}
            component={GameShopProduct}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.GAME_BETA_USER}
            component={GameBetaUser}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.GAME_BETA_REQUEST}
            component={GameBetaRequest}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.GAME_INFO}
            component={GameInfo}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.CONTACT_MESSAGE}
            component={ContactMessage}
          />
          <Route
            path={CONSTANTS.NAVIGATION_URL.GENERAL}
            component={General}
            />
          <Route exact path="/">
            <Redirect to={CONSTANTS.NAVIGATION_URL.MEMBERSHIP} />
          </Route>
          <Route path={CONSTANTS.NAVIGATION_URL.PLAN} component={Plan} />
          <Route
            path={CONSTANTS.NAVIGATION_URL.STRUCTURED_PROGRAM}
            component={StructuredProgram}
          />
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default App;
