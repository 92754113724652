import React from "react";
import style from "./StatusLabel.module.scss";

interface StatusLabel {
  text: string,
  color?:string, //Numbers of rgba color, not include parentheses
  colorBg?:string
}
const StatusLabel = (props: StatusLabel) => {
  const { text, color, colorBg } = props;
  return (
    <div className={style["StatusLabelWrapper"]}>
      <div
        className={style["StatusLabelMain"] + " StatusLabelMain"}
        style={{
          color: `rgb(${color})`,
          border: `1px solid rgba(${color}, 0.7)`,
          background: `${colorBg}`,
        }}
      >
        <div
          className={style["circle"]}
          style={{
            background: `rgb(${color})`,
          }}
        ></div>
        <div className={style["statusText"]}>{text}</div>
      </div>
    </div>
  );
};

export default StatusLabel;
